import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { exhaustMap, map, concatMap, catchError, withLatestFrom } from 'rxjs/operators';
import { GridDataService } from '../services/grid-data.service';
import {
  GridDataRefreshFailedAction,
  GridDataRefreshSuccessAction,
  RefreshGridDataAction,
} from '../actions/grid.actions';
import { GridsState } from '../reducers/grid.reducers';
import { translateDataSourceResultGroups } from '@progress/kendo-data-query';
import { Observable, of } from 'rxjs';
import { getGridById } from '../selectors/grid.selectors';

export function withBetterLatestFrom<TSource, TSecond>(
  second: (input: TSource) => Observable<TSecond>,
) {
  return function latestFromIsSuxx(source: Observable<TSource>) {
    return Observable.create((subscriber) => {
      const subscription = source
        .pipe(concatMap((action) => of(action).pipe(withLatestFrom(second(action)))))
        .subscribe(
          (value) => {
            try {
              subscriber.next(value);
            } catch (err) {
              subscriber.error(err);
            }
          },
          (err) => subscriber.error(err),
          () => subscriber.complete(),
        );
      return subscription;
    }) as Observable<[TSource, TSecond]>;
  };
}

@Injectable()
export class GridsEffects {
  constructor(
    private gridsService: GridDataService,
    private actions$: Actions,
    private store: Store<GridsState>,
  ) {}

  loadGridData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefreshGridDataAction),
      withBetterLatestFrom((x) => this.store.select(getGridById(x.id))),
      concatMap(([action, grid]) => {
        const settings = grid?.state ? grid.state : action.settings;
        const addition = action.addition ? action.addition : null;
        const payload = action.payload ? action.payload : null;
        return this.gridsService
          .fetchData(action.urlPostfix, settings as any, addition, payload)
          .pipe(
            map((x) => {
              const hasGroups = settings.group && settings.group.length;
              return GridDataRefreshSuccessAction({
                id: action.id,
                gridResponse: hasGroups ? translateDataSourceResultGroups(x.data) : x.data,
                total: x.total,
                gridErrors: x.errors,
              });
            }),
            catchError((_) => {
              return [GridDataRefreshFailedAction({ id: action.id })];
            }),
          );
      }),
    ),
  );
}
