import { createAction, props } from '@ngrx/store';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { GridErrors } from '../models/grid-errors.model';

export const RefreshGridDataAction = createAction(
  '[Grid Service] Grid Refresh data',
  props<{
    id: string;
    urlPostfix: string;
    settings?: DataSourceRequestState;
    addition?;
    payload?;
  }>(),
);

export const GridChangeSelections = createAction(
  '[Grid Service] Grid change selections',
  props<{ id: string /*, addedSelections: any[], removedSelections: any[]*/ }>(),
);

export const GridClearSelections = createAction(
  '[Grid Service] Grid clear selections',
  props<{ id: string }>(),
);

export const GridDataRefreshSuccessAction = createAction(
  '[Grid Service] Grid Data Loaded Success',
  props<{ id: string; gridResponse: any; total: number; gridErrors: GridErrors }>(),
);

export const GridDataRefreshFailedAction = createAction(
  '[Grid Service] Grid Data Loaded Fail',
  props<{ id: string }>(),
);

export const GridSetSelections = createAction(
  '[Grid Service] Grid set new selections',
  props<{ id: string; newSelections: any[] }>(),
);

export const GridSetSelectionsData = createAction(
  '[Grid Service] Grid set new selections data',
  props<{ id: string; newSelectionsData: any }>(),
);

export const GridSetState = createAction(
  '[Grid Service] Grid set new state',
  props<{ id: string; newState: DataSourceRequestState }>(),
);
