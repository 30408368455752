import { Injectable } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { GridsState } from '../reducers/grid.reducers';
import {
  GridChangeSelections,
  GridClearSelections,
  RefreshGridDataAction,
  GridSetSelections,
  GridSetSelectionsData,
  GridSetState,
} from '../actions/grid.actions';
import { Observable } from 'rxjs';
import { Grid } from '../models/grid.model';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import {
  getGridById,
  getGridPayloadById,
  getGridSelectionsById,
  getGridSelectionsDataById,
  getGridStateById,
} from '../selectors/grid.selectors';

@Injectable({
  providedIn: 'root',
})
export class GridService {
  constructor(private store: Store<GridsState>) {}

  getPayloadAndState(id: string): Observable<any> {
    const selector$ = createSelector(
      getGridStateById(id),
      getGridPayloadById(id),
      (state, payload) => ({
        state,
        payload,
      }),
    );
    return this.store.select(selector$);
  }

  getPayload(id: string): Observable<any> {
    return this.store.select(getGridPayloadById(id));
  }

  getSelections(id: string): Observable<any[]> {
    return this.store.select(getGridSelectionsById(id));
  }

  getSelectionsData(id: string): Observable<any> {
    return this.store.select(getGridSelectionsDataById(id));
  }

  getState(id: string): Observable<DataSourceRequestState> {
    return this.store.select(getGridStateById(id));
  }

  setSelections(id: string /*, addedSelections: any[], removedSelections: any[]*/) {
    this.store.dispatch(GridChangeSelections({ id /*, addedSelections, removedSelections*/ }));
  }

  clearSelections(id: string) {
    this.store.dispatch(GridClearSelections({ id }));
  }

  setNewSelections(id: string, newSelections: any[]) {
    this.store.dispatch(GridSetSelections({ id, newSelections }));
  }

  setNewSelectionsData(id: string, newSelectionsData: any) {
    this.store.dispatch(GridSetSelectionsData({ id, newSelectionsData }));
  }

  setNewState(id: string, newState: DataSourceRequestState) {
    this.store.dispatch(GridSetState({ id, newState }));
  }

  getData(
    id: string,
    postfixUrl: string,
    settings?: DataSourceRequestState,
    addition?,
    payload?,
  ): Observable<Grid> {
    this.store.dispatch(
      RefreshGridDataAction({
        id,
        urlPostfix: postfixUrl,
        settings: {
          take: settings && settings.take ? settings.take : 100,
          sort: settings && settings.sort ? settings.sort : undefined,
          filter: settings && settings.filter ? settings.filter : undefined,
          group: settings && settings.group ? settings.group : undefined,
          skip: settings && settings.skip ? settings.skip : 0,
          aggregates: settings && settings.aggregates ? settings.aggregates : undefined,
        },
        addition,
        payload,
      }),
    );
    return this.store.select(getGridById(id));
  }

  refreshData(id: string, postfixUrl: string, state?: DataStateChangeEvent, addition?, payload?) {
    this.store.dispatch(
      RefreshGridDataAction({
        id,
        urlPostfix: postfixUrl,
        settings: state,
        addition,
        payload,
      }),
    );
  }
}
